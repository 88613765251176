import api from '../api';
import {
  AppBase,
  AppSplitRecipientBankAccountBase,
  AppSplitRecipientBase,
} from '../../models/app';

type BankAccountRequest = {
  account_check_digit: string;
  account_number: string;
  bank: string;
  branch_check_digit?: string;
  branch_number: string;
  holder_document: string;
  holder_name: string;
  holder_type: 'individual' | 'company';
  metadata?: {
    [key: string]: string;
  };
  type: 'checking' | 'savings';
};

type TransferSettings = {
  transfer_enabled: boolean;
  transfer_interval: 'day';
  transfer_day: number;
};

type AnticipationSettings = {
  delay?: string;
  enabled: boolean;
  type: 'full' | '1025';
  volume_percentage: string;
};

type CreateRecipientProps = {
  automatic_anticipation_settings?: AnticipationSettings;
  code?: string;
  default_bank_account: BankAccountRequest;
  description?: string;
  document: string;
  email: string;
  metadata?: {
    [key: string]: string;
  };
  name: string;
  transfer_settings?: TransferSettings;
  type: 'individual' | 'company';
};

interface ICreateAppProps {
  description?: string;
  hookUrl?: string;
  name: string;
  ref: string;
  paymentProviderPublicKey: string;
  paymentProviderSecretKey: string;
  splitRecipient: CreateRecipientProps;
  storeId?: string;
}

interface IDetailedAppSplitRecipient extends AppSplitRecipientBase {
  bankAccount: AppSplitRecipientBankAccountBase;
}

export interface INewApp extends AppBase {
  splitRecipient: IDetailedAppSplitRecipient;
}

export const createAppsService = async ({
  description,
  hookUrl,
  name,
  ref,
  paymentProviderPublicKey,
  paymentProviderSecretKey,
  splitRecipient,
  storeId,
}: ICreateAppProps): Promise<INewApp> => {
  const { data } = await api.post<INewApp>('/apps', {
    description,
    hookUrl,
    name,
    ref,
    paymentProviderPublicKey,
    paymentProviderSecretKey,
    splitRecipient,
    storeId,
  });

  return data;
};
