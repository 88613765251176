import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Avatar,
  useToast,
  ButtonGroup,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedApp,
  showAppsService,
} from '../../../../../services/Apps/ShowAppsService';
import { deleteAppsService } from '../../../../../services/Apps/DeleteAppsService';

interface IApp extends IDetailedApp {
  formattedDate: string;
}

interface ILocationState {
  appId: string;
}

export const AppDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { appId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [app, setApp] = useState<IApp>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadApp(): Promise<void> {
      try {
        const appData = await showAppsService(appId);

        const appWithformattedDate = {
          ...appData,
          formattedDate: maskDate(appData.createdAt),
        };

        setApp(appWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do app, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadApp();
  }, [appId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteApp = useCallback(async () => {
    try {
      await deleteAppsService(appId);

      toast({
        title: 'Excluído com sucesso',
        description: 'O app foi excluído corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/apps');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description: 'Ocorreu um erro ao excluir o app, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [appId, push, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteApp}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do app
          </Heading>

          <ButtonGroup>
            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/apps/update',
                state: {
                  appId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {app && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Avatar size="2xl" name={app.name} src={app.avatarUrl} />
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {app.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {app.description}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              <Text>
                Ref:
                <Text as="span" color="gray.500" ml="2">
                  {app.ref}
                </Text>
              </Text>
              <Text>
                Url:
                <Text as="span" color="gray.500" ml="2">
                  {app.hookUrl}
                </Text>
              </Text>

              <HStack>
                <Text>JHSF-ID store id:</Text>
                <Text>{app.storeId || '-'}</Text>
              </HStack>
            </SimpleGrid>

            <Heading mt="8" size="md" fontWeight="normal">
              Recebedor
            </Heading>

            <Divider my="6" />

            <Heading mt="8" size="md" fontWeight="normal">
              Conta de recebimento
            </Heading>

            <Divider my="6" />
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
