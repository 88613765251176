import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Heading,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createAppsService } from '../../../../../services/Apps/CreateAppsService';
import {
  RegisterAppFormData,
  RegisterAppForm,
} from '../components/RegisterAppForm';
import {
  AppSplitRecipientData,
  AppSplitRecipientForm,
} from '../components/AppSplitRecipientForm';

interface IApp extends RegisterAppFormData {
  splitRecipient?: AppSplitRecipientData;
}

interface ICreateAppProps extends RegisterAppFormData {
  splitRecipient: AppSplitRecipientData;
}

export const AppRegister = (): JSX.Element => {
  const { push } = useHistory();
  const toast = useToast();

  const [tabIndex, setTabIndex] = useState(0);
  const [app, setApp] = useState<IApp>();

  const handleTabChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const handleNewApp = useCallback((appData: IApp) => {
    setApp(appData);
    setTabIndex(1);
  }, []);

  const handleSubmitApp = useCallback(
    async ({
      splitRecipient,
      description,
      name,
      paymentProviderPublicKey,
      paymentProviderSecretKey,
      ref,
      storeId,
      hookUrl,
    }: ICreateAppProps) => {
      try {
        const newApp = await createAppsService({
          description,
          name,
          paymentProviderPublicKey,
          paymentProviderSecretKey,
          ref,
          hookUrl,
          splitRecipient: {
            document: splitRecipient.document,
            email: splitRecipient.email,
            name: splitRecipient.name,
            type: splitRecipient.type,
            description,
            default_bank_account: {
              account_check_digit: splitRecipient.bankAccount.accountCheckDigit,
              account_number: splitRecipient.bankAccount.accountNumber,
              bank: splitRecipient.bankAccount.bank,
              branch_check_digit: splitRecipient.bankAccount.branchCheckDigit,
              branch_number: splitRecipient.bankAccount.branchNumber,
              holder_document: splitRecipient.bankAccount.holderDocument,
              holder_name: splitRecipient.bankAccount.holderName,
              holder_type: splitRecipient.bankAccount.holderType,
              type: splitRecipient.bankAccount.type,
            },
          },
          storeId,
        });

        setApp(newApp);

        push('/apps/details', { appId: newApp.id });

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O app foi cadastrado corretamente!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description: 'Ocorreu um erro ao cadastrar app, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [push, toast],
  );

  const handleNewSplitRecipient = useCallback(
    (splitRecipientData: AppSplitRecipientData) => {
      if (app) {
        const newAppData = { ...app, splitRecipient: splitRecipientData };

        setApp(newAppData);

        handleSubmitApp(newAppData);
      }
    },
    [app, handleSubmitApp],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Cadastrar App
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Tabs
          index={tabIndex}
          onChange={handleTabChange}
          variant="enclosed-colored"
        >
          <TabList>
            <Tab>App</Tab>
            <Tab>Recebedor</Tab>
          </TabList>

          <TabPanels>
            <TabPanel mt="4">
              <RegisterAppForm app={app} onSubmit={handleNewApp} />
            </TabPanel>

            <TabPanel mt="4">
              <AppSplitRecipientForm
                splitRecipient={app?.splitRecipient}
                onSubmit={handleNewSplitRecipient}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
};
