import { Stack } from '@chakra-ui/react';
import {
  RiBriefcase4Line,
  RiContactsLine,
  RiDashboardLine,
  RiInputMethodLine,
  RiShieldUserLine,
  RiShoppingCartLine,
  RiSlideshowLine,
} from 'react-icons/ri';
import { NavLink } from './NavLink';
import { NavSection } from './NavSection';

export const SidebarNav = (): JSX.Element => (
  <Stack spacing="12" align="flex-start" minWidth="170px">
    <NavSection title="Geral">
      <NavLink icon={RiDashboardLine} to="/dashboard">
        Dashboard
      </NavLink>

      <NavLink icon={RiInputMethodLine} to="/apps">
        Apps
      </NavLink>

      <NavLink icon={RiShoppingCartLine} to="/orders">
        Pedidos
      </NavLink>

      <NavLink icon={RiBriefcase4Line} to="/recipients">
        Recebedores
      </NavLink>

      <NavLink icon={RiShieldUserLine} to="/customers">
        Compradores
      </NavLink>
    </NavSection>

    <NavSection title="Conciliação fiscal">
      <NavLink icon={RiSlideshowLine} to="/reports">
        Relatórios
      </NavLink>
    </NavSection>

    <NavSection title="Administrativo">
      <NavLink icon={RiContactsLine} to="/users">
        Usuários
      </NavLink>
    </NavSection>
  </Stack>
);
