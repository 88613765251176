import { Switch } from 'react-router-dom';
import { UserDetails } from '../pages/App/Adm/Users/UserDetails';
import { UserList } from '../pages/App/Adm/Users/UserList';
import { UserRegister } from '../pages/App/Adm/Users/UserRegister';
import { UserUpdate } from '../pages/App/Adm/Users/UserUpdate';
import { AppDetails } from '../pages/App/General/Apps/AppDetails';
import { AppList } from '../pages/App/General/Apps/AppList';
import { AppRegister } from '../pages/App/General/Apps/AppRegister';
import { AppUpdate } from '../pages/App/General/Apps/AppUpdate';
import { CustomerDetails } from '../pages/App/General/Customers/CustomerDetails';
import { CustomerList } from '../pages/App/General/Customers/CustomerList';
import { Dashboard } from '../pages/App/General/Dashboard';
import { OrderDetails } from '../pages/App/General/Orders/OrderDetails';
import { OrderList } from '../pages/App/General/Orders/OrderList';
import { RecipientDetails } from '../pages/App/General/Recipients/RecipientDetails';
import { RecipientList } from '../pages/App/General/Recipients/RecipientList';
import { InvoiceReportList } from '../pages/App/InvoiceReports/InvoiceReportList';
import { Profile } from '../pages/App/Profile';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { SignIn } from '../pages/Auth/SignIn';
import { Route } from './Route';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/users" exact component={UserList} isPrivate />
    <Route path="/users/details" component={UserDetails} isPrivate />
    <Route path="/users/register" component={UserRegister} isPrivate />
    <Route path="/users/update" component={UserUpdate} isPrivate />
    <Route path="/users/passwords/forgot" component={ForgotPassword} />
    <Route path="/users/passwords/reset" component={ResetPassword} />
    <Route path="/profile" exact component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/apps/" exact component={AppList} isPrivate />
    <Route path="/apps/details" component={AppDetails} isPrivate />
    <Route path="/apps/register" component={AppRegister} isPrivate />
    <Route path="/apps/update" component={AppUpdate} isPrivate />
    <Route path="/recipients/" exact component={RecipientList} isPrivate />
    <Route
      path="/recipients/details"
      exact
      component={RecipientDetails}
      isPrivate
    />
    <Route path="/customers/" exact component={CustomerList} isPrivate />
    <Route
      path="/customers/details"
      exact
      component={CustomerDetails}
      isPrivate
    />
    <Route path="/orders/" exact component={OrderList} isPrivate />
    <Route path="/orders/details" exact component={OrderDetails} isPrivate />
    <Route path="/reports/" exact component={InvoiceReportList} isPrivate />
  </Switch>
);
