import { Box, Heading, Flex, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { RecipientTable, IRecipient } from './components/RecipientTable';
import { listRecipientsService } from '../../../../../services/Recipients/ListRecipientsService';

interface IFilter {
  filter: string;
  value: string;
}

interface ILoadRecipientsProps {
  appName?: string;
  email?: string;
  name?: string;
  sort?: 'name' | 'email' | 'commission' | 'type' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const RecipientList = (): JSX.Element => {
  const toast = useToast();

  const [recipientsList, setRecipientsList] = useState<IRecipient[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentSort, setCurrentSort] = useState<
    'name' | 'email' | 'commission' | 'type' | 'createdAt'
  >();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadRecipients = useCallback(
    async ({
      appName,
      name,
      email,
      page,
      sort = 'createdAt',
      order = 'ASC',
    }: ILoadRecipientsProps) => {
      try {
        const recipients = await listRecipientsService({
          appName: appName || undefined,
          name: name || undefined,
          email: email || undefined,
          page,
          sort,
          order,
          limit: 20,
        });

        const formattedRecipients = recipients.items.map((recipient) => ({
          ...recipient,
          formattedDate: maskShortDate(recipient.createdAt),
          formattedCommission: recipient.commission
            ? recipient.commission / 100
            : null,
        }));

        setTotalPages(recipients.pages);
        setRecipientsList(formattedRecipients);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos recebeedores, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleFilter = useCallback(
    ({ filter, value }: IFilter) => {
      setCurrentFilters({ [filter]: value });

      loadRecipients({ [filter]: value });
    },
    [loadRecipients],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'email' | 'commission' | 'type' | 'createdAt') => {
      setCurrentSort(sort);
    },
    [],
  );

  const handleOrderList = useCallback(
    (order: 'ASC' | 'DESC') => {
      loadRecipients({
        ...currentFilters,
        page: currentPage,
        sort: currentSort,
        order,
      });
    },
    [currentFilters, currentPage, currentSort, loadRecipients],
  );

  useEffect(() => {
    loadRecipients({
      ...currentFilters,
      page: currentPage,
      sort: currentSort,
    });
  }, [currentFilters, currentPage, currentSort, loadRecipients]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Recebedores
          </Heading>
        </Flex>

        <RecipientTable
          mt="4"
          recipients={recipientsList}
          onFilter={debounceHandleFilter}
          defaultFilterBy="name"
          defaultSortBy="createdAt"
          onSort={handleSortList}
          onOrder={handleOrderList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
