import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { UpdateAppFormData, UpdateAppForm } from '../components/UpdateAppForm';
import {
  AppSplitRecipientData,
  AppSplitRecipientForm,
} from '../components/AppSplitRecipientForm';
import { showAppsService } from '../../../../../services/Apps/ShowAppsService';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import { updateAppsService } from '../../../../../services/Apps/UpdateAppsService';

interface IApp extends UpdateAppFormData {
  splitRecipient?: AppSplitRecipientData;
}

interface ILocationState {
  appId: string;
}

interface IUpdateAppProps extends UpdateAppFormData {
  splitRecipient: AppSplitRecipientData;
}

export const AppUpdate = (): JSX.Element => {
  const { push } = useHistory();
  const toast = useToast();
  const {
    state: { appId },
  } = useLocation<ILocationState>();

  const [tabIndex, setTabIndex] = useState(0);
  const [app, setApp] = useState<IApp>();

  useEffect(() => {
    async function loadApp(): Promise<void> {
      try {
        const appData = await showAppsService(appId);

        const appWithformattedDate = {
          ...appData,
          formattedDate: maskDate(appData.createdAt),
        };

        setApp(appWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do app, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadApp();
  }, [appId, toast]);

  const handleTabChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const handleUpdatedApp = useCallback((appData: IApp) => {
    setApp(appData);
    setTabIndex(1);
  }, []);

  const handleSubmitApp = useCallback(
    async ({
      splitRecipient,
      description,
      name,
      ref,
      hookUrl,
      storeId,
    }: IUpdateAppProps) => {
      try {
        const updatedApp = await updateAppsService({
          description,
          name,
          ref,
          hookUrl,
          appId,
          splitRecipient: {
            document: splitRecipient.document,
            email: splitRecipient.email,
            name: splitRecipient.name,
            type: splitRecipient.type,
            status: 'active',
            default_bank_account: {
              account_check_digit: splitRecipient.bankAccount.accountCheckDigit,
              account_number: splitRecipient.bankAccount.accountNumber,
              bank: splitRecipient.bankAccount.bank,
              branch_check_digit: splitRecipient.bankAccount.branchCheckDigit,
              branch_number: splitRecipient.bankAccount.branchNumber,
              holder_document: splitRecipient.bankAccount.holderDocument,
              holder_name: splitRecipient.bankAccount.holderName,
              holder_type: splitRecipient.bankAccount.holderType,
              type: splitRecipient.bankAccount.type,
            },
          },
          storeId,
        });

        setApp(updatedApp);

        push('/apps/details', { appId: updatedApp.id });

        toast({
          title: 'Editado com sucesso',
          description: 'O app foi editado corretamente!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description: 'Ocorreu um erro ao editar app, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [appId, push, toast],
  );

  const handleUpdatedSplitRecipient = useCallback(
    (splitRecipientData: AppSplitRecipientData) => {
      if (app) {
        const updatedAppData = { ...app, splitRecipient: splitRecipientData };

        setApp(updatedAppData);

        handleSubmitApp(updatedAppData);
      }
    },
    [app, handleSubmitApp],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Editar App
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Tabs
          index={tabIndex}
          onChange={handleTabChange}
          variant="enclosed-colored"
        >
          <TabList>
            <Tab>App</Tab>
            <Tab>Recebedor</Tab>
          </TabList>

          <TabPanels>
            <TabPanel mt="4">
              <UpdateAppForm app={app} onSubmit={handleUpdatedApp} />
            </TabPanel>

            <TabPanel mt="4">
              <AppSplitRecipientForm
                splitRecipient={app?.splitRecipient}
                onSubmit={handleUpdatedSplitRecipient}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
};
