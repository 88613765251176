import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../components/Pagination';
import { IOrderInvoiceReportListItem } from '../../../../../../services/Orders/ListOrdersInvoiceReportsService';

export interface IInvoiceReportOrder extends IOrderInvoiceReportListItem {
  formattedCommissionAmount: string;
  formattedCreatedAt: string;
  formattedRecipientDocument: string;
  formattedTotalAmount: string;
}

interface IInvoiceReportTableProps extends BoxProps {
  orders: IInvoiceReportOrder[];
  currentPage?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

export const InvoiceReportTable = ({
  orders,
  currentPage,
  onPageChange,
  totalPages,
  ...rest
}: IInvoiceReportTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th w="16">Código</Th>
          <Th>App</Th>
          <Th>Recebedor</Th>
          <Th>Nome fantasia</Th>
          <Th>Cliente</Th>
          <Th w="18">Valor total</Th>
          <Th w="16">Valor comissão</Th>
          <Th w="16">Data de abertura</Th>
          <Th w="8">Detalhes</Th>
        </Tr>
      </Thead>

      <Tbody>
        {orders.map((order) => (
          <Tr key={order.paymentProviderOrderId}>
            <Td fontSize="sm">{order.code}</Td>

            <Td>
              <Box>
                <Text>{order.appName}</Text>
                <Text fontSize="sm" color="gray.500">
                  {`Ref: ${order.appRef}`}
                </Text>
              </Box>
            </Td>

            <Td>
              <Box>
                <Text>{order.recipientName}</Text>
                <Text fontSize="sm" color="gray.500">
                  {`CNPJ: ${order.formattedRecipientDocument}`}
                </Text>
              </Box>
            </Td>

            <Td>{order.recipientTradingName || '-'}</Td>

            <Td>{order.customerName}</Td>

            <Td>{order.formattedTotalAmount}</Td>

            <Td>{order.formattedCommissionAmount}</Td>

            <Td>{order.formattedCreatedAt}</Td>

            <Td textAlign="center">
              <LinkButton
                to={{
                  pathname: '/orders/details',
                  state: {
                    orderId: order.paymentProviderOrderId,
                  },
                }}
              >
                <Icon as={RiPencilLine} fontSize="16" />
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>

    {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    )}
  </Box>
);
